const h = errors => {
	let msg = []
	errors.forEach(e => {
		if (e.message.includes("no rows in result set")) {
			console.error(e.message, e.path)
			msg = [...msg, "La donnée demandé n'a pas été trouvé"]
		}
		if (e.message.includes("unique_violation")) {
			console.error(e.message, e.path)
			msg = [...msg, "La donnée saisi est déjà utilisé"]
		}
		if (e.message.includes("bad role")) {
			console.error(e.message, e.path)
			msg = [...msg, "Vous n'etes pas autoriser à faire cela"]
		}
	});

	return msg
}

export const hGql = (message,locations,path) =>{
	let msg=[]
	if (message.includes("no rows in result set")) {
		console.error(locations, path)
		msg = [...msg, "La donnée demandé n'a pas été trouvé"]
	}
	if (message.includes("unique_violation")) {
		console.error(locations, path)
		msg = [...msg, "La donnée saisi est déjà utilisé"]
	}
	if (message.includes("bad role")) {
		console.error(locations, path)
		msg = [...msg, "Vous n'etes pas autoriser à faire cela"]
	}
	if (message.includes("IBAN")) {
		console.error(locations, path)
		msg = [...msg, "Le format de l'IBAN n'est pas correcte"]
	}
	if (message.includes("e164")) {
		console.error(locations, path)
		msg = [...msg, "Le format du numéro de téléphone n'est pas correcte"]
	}
	if (message.includes("not found in database") && path.join("/").includes("login")) {
		console.error(locations, path)
		msg = [...msg, "Le mot de passe ou l'adresse mail est incorrecte"]
	}
	if (message.includes("not found in database") && path.join("/").includes("recreatePassword")) {
		console.error(locations, path)
		msg = [...msg, "Le code saisie est incorrecte"]
	}
	if (message.includes("should be a valid email") ) {
		console.error(locations, path)
		msg = [...msg, "L'adresse mail n'est pas valide"]
	}
	if (message.includes("sponsor code does not exist") ) {
		console.error(locations, path)
		msg = [...msg, "Ce code n'existe pas"]
	}
	if (message.includes("the cover is required") ) {
		console.error(locations, path)
		msg = [...msg, "Vous devez ajouter une image"]
	}
	if (message.includes("invalid UUID") && path.join("/").includes("getlead")) {
		console.error(locations, path)
		msg = [...msg, "L'identifiant entrée dans l'url n'est pas valide"]
	}
	if (message.includes("greater than or equal than 1000") && path.join("/").includes("askBuisnessTransfer")) {
		console.error(locations, path)
		msg = [...msg, "Le montant de retrait minimum est de 10 €"]
	}
	if (message.includes("not enough money") && path.join("/").includes("askBuisnessTransfer")) {
		console.error(locations, path)
		msg = [...msg, "Vous n'avez pas assez de fond sur votre wallet"]
	}
	if (message.includes("ALREADY_CREATE_STAR_SUBSCRIPTION")) {
		console.error(locations, path)
		msg = [...msg, "Vous ne pouvez pas mettre à jour l'abonnement STAR (une fois par jour maximum)"]
	}
	if (message.includes("ALREADY EXIST") && path.join("/").includes("stripeInitialPaymentIntent")) {
		console.error(locations, path)
		msg = [...msg, "Vous avez dèjà souscrit à un abonnement avec cet email "]
	}
	if (message.includes("card_declined")) {
		console.error(locations, path)
		msg = [...msg, "Le paiement ne peut pas se faire avec cette carte bancaire"]
	}
	if (message.includes("authentication_required")) {
		console.error(locations, path)
		msg = [...msg, "Cette carte ne peut pas être utilisé car elle nécessite une authentification pour chaque prévélement "]
	}
	if (message.includes("CANNOT DISABLE STAR")) {
		console.error(locations, path)
		msg = [...msg, "Vous ne pouvez pas désactiver l'option star pour aujourd'hui "]
	}
	if (message.includes("NO_CARDS_SAVED_FOR_THIS_USER")) {
		console.error(locations, path)
		msg = [...msg, "Aucune carte n'est associé à votre compte "]
	}
	if (message.includes("invoice_payment_intent_requires_action") || message.includes("INVOICE PAYMENT") ) {
		console.error(locations, path)
		msg = [...msg, "Votre banque refuse le paiement "]
	}
	if (message.includes("NO_CARD_FOR_THIS_CUSTOMER")) {
		console.error(locations, path)
		msg = [...msg, "Aucune carte liée à cette entreprise"]
	}
	if (message.includes("DELINQUENT")) {
		console.error(locations, path)
		msg = [...msg, "Veuillez mettre à jour votre CB et réessayez"]
	}
	
	


	if (msg.length ===0 && !message.includes("missing jwt key") ) msg.push("Une erreur est survenue")
	//console.log(msg)
	return msg
}
export default h