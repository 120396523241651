import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './layouts/Layout';
import 'react-datetime/css/react-datetime.css';
import 'react-toastify/dist/ReactToastify.css';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  Observable,
} from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
// import { WebSocketLink } from "@apollo/client/link/ws";
import { onError } from "@apollo/client/link/error";
// import { SubscriptionClient } from "subscriptions-transport-ws";
import { createUploadLink } from "apollo-upload-client";
import refreshToken from './helpers/refreshToken';
import { toast } from 'react-toastify';
// eslint-disable-next-line
import h, { hGql } from './helpers/humanResp';


const API_URL = process.env.REACT_APP_API_URL + "/query";
// const API_WS = process.env.REACT_APP_API_WS + "/query";

const App = () => {

  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        //toast.error(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}`, { autoClose: false })
        hGql(message, locations, path).forEach(m => toast.error(m))
      );
    }
    if (networkError) {
      if (networkError.statusCode === 403) {
        return new Observable(observer => {
          refreshToken().then(() => {

            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer)
            };
            window.location.reload()
            forward(operation).subscribe(subscriber);
           
          })
            .catch(error => {
              observer.error(error);
            });
        });
      }
    };
  });

  const uploadLinkWithUpload = createUploadLink({ uri: API_URL })

  // const token = localStorage.getItem('accessToken');
  // const wsClient = new SubscriptionClient(API_WS, {
  //   reconnect: true,
  //   connectionParams: {
  //     headers: {
  //       "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
  //       authorization: token ? `Bearer ${token}` : ""
  //     },
  //   },
  // })

  // const wsLink = new WebSocketLink(wsClient);

  const authLink = setContext(async (_, { headers }) => {
    const access_token = localStorage.getItem('accessToken');
    return {
      headers: {
        ...headers,
        authorization: access_token ? `Bearer ${access_token}` : "",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`
      }
    }
  });

  const baseOptions = {
    errorPolicy: "all"
  }

  const client = new ApolloClient({
    defaultOptions: {
      watchQuery: baseOptions,
      query: baseOptions,
      mutate: baseOptions,
    },
    link: ApolloLink.from([authLink, errorLink, uploadLinkWithUpload]),
    cache: new InMemoryCache(),
    name: `${process.env.REACT_APP_NAME}`,
    version: `${process.env.REACT_APP_API_VERSION}`
  });

  return (
    <ApolloProvider client={client}>
      <Router basename={process.env.PUBLIC_URL}>
        <Layout />
      </Router>
    </ApolloProvider>
  );
};

export default App;
